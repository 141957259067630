import type {
  NodeName,
  NodeAttributeDef,
  MarkName,
  NotebookSharedRole,
  NotebookAuthzAction,
  ParamWidgetType,
  SqlParamEscapeType,
  RootNodeJson,
} from '../types/notebook';
import { COMMENT_NODE_NAMES } from './comment-thread';

export const NOTEBOOK_SCHEMA_VERSION = '1.2.0';

export const NODE_NAMES = {
  // 以下、Tiptap定義
  // (MEMO: editor.schema.spec.nodes で実体確認できる)
  DOC: 'doc',
  TEXT: 'text',
  HARD_BREAK: 'hardBreak',
  PARAGRAPH: 'paragraph',
  HEADING: 'heading',
  COLUMN_LAYOUT: 'columnLayout',
  COLUMN_ITEM: 'columnItem',
  BLOCKQUOTE: 'blockquote',
  HORIZONTAL_RULE: 'horizontalRule',
  LIST: 'list',
  TABLE: 'table',
  TABLE_ROW: 'tableRow',
  TABLE_HEADER: 'tableHeader',
  TABLE_CELL: 'tableCell',
  CODE_BLOCK: 'codeBlock',
  SQL_BLOCK: 'sqlBlock',
  SQL_BLOCK_NAME: 'sqlBlockName',
  SQL_BLOCK_BODY: 'sqlBlockBody',
  SQL_BLOCK_REF: 'sqlBlockRef',
  SQL_BLOCK_RESULT: 'sqlBlockResult',
  SQL_BLOCK_RESULT_TABLE: 'sqlBlockResultTable',
  SQL_BLOCK_RESULT_CHART: 'sqlBlockResultChart',
  SQL_BLOCK_RESULT_STATS: 'sqlBlockResultStats',
  SQL_TABLE_REF: 'sqlTableRef',
  SQL_QUERY_REF: 'sqlQueryRef',
  SQL_PARAM_REF: 'sqlParamRef',
  BLOCK_CHART: 'blockChart',
  INLINE_CHART: 'inlineChart',
  SQL_RESULT_SCALAR: 'sqlResultScalar',
  IMAGE_BLOCK: 'imageBlock',
  VIDEO_BLOCK: 'videoBlock',
  TABLE_VIEWER_BLOCK: 'tableViewerBlock',
  MERMAID_BLOCK: 'mermaidBlock',
  BLOCK_EQUATION: 'blockEquation',
  INLINE_EQUATION: 'inlineEquation',
  CALLOUT_BLOCK: 'calloutBlock',
  OEMBED_BLOCK: 'oEmbedBlock',
  NOTEBOOK_LINK: 'notebookLink',
  MENTION: COMMENT_NODE_NAMES.MENTION,
} as const;

export const MARK_NAMES = {
  LINK: 'link',
  BOLD: 'bold',
  CODE: 'code',
  ITALIC: 'italic',
  STRIKE: 'strike',
  UNDERLINE: 'underline',
  HIGHLIGHT: 'highlight',
  COMMENT: 'comment',
} as const;

export const NODE_GROUPS = {
  SQL_EXPRESSION: 'sqlExpression',
  SQL_JOB_RESULT_VIEW: 'sqlJobResultView',
  INLINE: 'inline',
} as const;

export const SQL_PARAM_ESCAPE_TYPES = {
  STRING: 'STRING',
  STRING_ARRAY: 'STRING_ARRAY',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
} as const;

export const SQL_PARAM_ESCAPE_CONTEXT_QUOTE = {
  DEFAULT: 'DEFAULT',
  SINGLE_QUOTE: 'SINGLE_QUOTE',
  DOUBLE_QUOTE: 'DOUBLE_QUOTE',
  TRIPLE_SINGLE_QUOTE: 'TRIPLE_SINGLE_QUOTE',
  TRIPLE_DOUBLE_QUOTE: 'TRIPLE_DOUBLE_QUOTE',
  BACKTICK: 'BACKTICK',
} as const;

export const SQL_PARAM_DISPLAY_ESCAPE_TYPES: { [key in SqlParamEscapeType]: string } = {
  STRING: 'STRING',
  STRING_ARRAY: 'STRING[]',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
} as const;

// パラメータ入力時に NULL と空文字列の区別が難しいので、ゼロ値を設定して NULL にはしない仕様にする
export const SQL_PARAM_ESCAPE_TYPE_ZERO_VALUES: { [key in SqlParamEscapeType]: any } = {
  STRING: '',
  STRING_ARRAY: [],
  NUMBER: 0,
  BOOLEAN: false,
  DATE: '1970-01-01',
} as const;

export const PARAM_WIDGET_TYPES = {
  TEXT_INPUT: 'TEXT_INPUT',
  TEXT_MULTI_INPUT: 'TEXT_MULTI_INPUT',
  TEXT_SELECT: 'TEXT_SELECT',
  TEXT_MULTI_SELECT: 'TEXT_MULTI_SELECT',
  NUMBER_INPUT: 'NUMBER_INPUT',
  NUMBER_SELECT: 'NUMBER_SELECT',
  DATE_INPUT: 'DATE_INPUT',
  DATE_RANGE: 'DATE_RANGE',
  CHECKBOX: 'CHECKBOX',
} as const;

export const PARAM_WIDGET_TYPES_SELECT_OPTIONS = [
  PARAM_WIDGET_TYPES.TEXT_SELECT,
  PARAM_WIDGET_TYPES.TEXT_MULTI_SELECT,
  PARAM_WIDGET_TYPES.NUMBER_SELECT,
] as ParamWidgetType[];

export const PARAM_WIDGET_NAMES: { [key in ParamWidgetType]: string } = {
  TEXT_INPUT: 'Text input',
  TEXT_MULTI_INPUT: 'Text multi input',
  TEXT_SELECT: 'Text select',
  TEXT_MULTI_SELECT: 'Text multi select',
  NUMBER_INPUT: 'Number input',
  NUMBER_SELECT: 'Number select',
  DATE_INPUT: 'Date input',
  DATE_RANGE: 'Date range',
  CHECKBOX: 'Checkbox',
} as const;

export const PARAM_WIDGET_DISPLAY_DATATYPES: { [key in ParamWidgetType]: string } = {
  TEXT_INPUT: 'STRING',
  TEXT_MULTI_INPUT: 'STRING[]',
  TEXT_SELECT: 'STRING',
  TEXT_MULTI_SELECT: 'STRING[]',
  NUMBER_INPUT: 'NUMBER',
  NUMBER_SELECT: 'NUMBER',
  DATE_INPUT: 'DATE',
  DATE_RANGE: '[DATE, DATE]',
  CHECKBOX: 'BOOLEAN',
} as const;

export const PARAM_WIDGET_INITIAL_VALUES: { [key in ParamWidgetType]: string } = {
  TEXT_INPUT: '""',
  TEXT_MULTI_INPUT: '[]',
  TEXT_SELECT: '""',
  TEXT_MULTI_SELECT: '[]',
  NUMBER_INPUT: '0',
  NUMBER_SELECT: '0',
  DATE_INPUT: '"1970-01-01"',
  DATE_RANGE: '["1970-01-01", "1970-01-01"]',
  CHECKBOX: 'false',
} as const;

export const PARAM_SELECT_OPTIONS_SOURCE_TYPES = {
  TEXT: 'TEXT',
  SQL: 'SQL',
  TABLE: 'TABLE',
} as const;

export const SQL_BLOCK_VIEW_MODES = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_RESULTS: 'SHOW_RESULTS',
  HIDDEN: 'HIDDEN',
} as const;

export const NODE_DEFS: {
  [nodeName in NodeName]: {
    topNode?: boolean;
    content?: string;
    atom?: boolean;
    marks?: string;
    inline?: boolean;
    group?: string;
    defining?: boolean;
    isolating?: boolean;
    selectable?: boolean;
    code?: boolean;
    priority?: number;
    htmlTagName?: 'div' | 'code' | 'span' | 'p';
    attributesDef?: { [key: string]: NodeAttributeDef };
  };
} = {
  // （schema生成のために使っている）
  [NODE_NAMES.DOC]: {
    topNode: true,
    content: `${NODE_NAMES.HEADING} block*`,
  },
  [NODE_NAMES.TEXT]: {
    group: NODE_GROUPS.INLINE,
  },
  [NODE_NAMES.HARD_BREAK]: {
    inline: true,
    group: NODE_GROUPS.INLINE,
    selectable: false,
  },
  [NODE_NAMES.PARAGRAPH]: {
    group: 'block',
    content: `${NODE_GROUPS.INLINE}*`,
    htmlTagName: 'p',
    attributesDef: {
      textAlign: { datatype: 'STRING' },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.HEADING]: {
    content: 'text*',
    group: 'block',
    defining: true,
    attributesDef: {
      level: {
        datatype: 'NUMBER',
        default: 1,
      },
      hideDefault: { datatype: 'BOOLEAN' },
      disabledHide: { datatype: 'BOOLEAN' },
      textAlign: { datatype: 'STRING' },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.COLUMN_LAYOUT]: {
    content: `${NODE_NAMES.COLUMN_ITEM}+`, // 2つ以上の columnItem が入る前提だが transaction の過程で1つに統合されるため + で定義
    group: 'block',
    isolating: true,
    attributesDef: {
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.COLUMN_ITEM]: {
    content: `(${NODE_NAMES.PARAGRAPH} | ${NODE_NAMES.LIST} | ${NODE_NAMES.BLOCK_CHART})+`,
    isolating: true,
  },
  [NODE_NAMES.BLOCKQUOTE]: {
    content: `(${NODE_NAMES.PARAGRAPH} | ${NODE_NAMES.LIST})+`,
    group: 'block',
    defining: true,
  },
  [NODE_NAMES.HORIZONTAL_RULE]: {
    group: 'block',
  },
  [NODE_NAMES.LIST]: {
    group: 'block',
    defining: true,
    content: NODE_NAMES.PARAGRAPH,
    attributesDef: {
      kind: { datatype: 'STRING', default: 'BULLET' },
      depth: { datatype: 'NUMBER', default: 0 },
      taskChecked: { datatype: 'BOOLEAN' },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.TABLE]: {
    content: 'tableRow+',
    isolating: true,
    group: 'block',
    attributesDef: {
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.TABLE_ROW]: {
    content: '(tableCell | tableHeader)*',
  },
  [NODE_NAMES.TABLE_HEADER]: {
    content: `(${NODE_NAMES.PARAGRAPH} | ${NODE_NAMES.LIST})+`,
    isolating: true,
  },
  [NODE_NAMES.TABLE_CELL]: {
    content: `(${NODE_NAMES.PARAGRAPH} | ${NODE_NAMES.LIST})+`,
    isolating: true,
    attributesDef: {
      colspan: {
        datatype: 'NUMBER',
      },
      rowspan: {
        datatype: 'NUMBER',
      },
      colwidth: {
        datatype: 'NUMBER',
      },
    },
  },
  [NODE_NAMES.CODE_BLOCK]: {
    content: 'text*',
    marks: '',
    group: 'block',
    code: true,
    defining: true,
    isolating: true, // gapcursor 使うために必要
    attributesDef: {
      language: {
        datatype: 'STRING',
      },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.SQL_BLOCK]: {
    content: `${NODE_NAMES.SQL_BLOCK_NAME} ${NODE_NAMES.SQL_BLOCK_BODY} ${NODE_NAMES.SQL_BLOCK_RESULT}?`,
    marks: '',
    group: 'block',
    isolating: true, // gapcursor 使うために必要
    attributesDef: {
      sqlId: { datatype: 'STRING' },
      connId: { datatype: 'STRING' },
      disableCache: { datatype: 'BOOLEAN' },
      defaultViewMode: { datatype: 'STRING' },
      linkId: { datatype: 'STRING' },
      args: {
        datatype: 'JSON',
        default: [],
      },
    },
  },
  [NODE_NAMES.SQL_BLOCK_NAME]: {
    content: 'text*',
    group: '',
    marks: 'comment',
    attributesDef: {},
  },
  [NODE_NAMES.SQL_BLOCK_BODY]: {
    content: `(text | ${NODE_GROUPS.SQL_EXPRESSION})*`,
    marks: 'comment',
    group: '',
    code: true,
    htmlTagName: 'code',
    attributesDef: {
      height: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.SQL_BLOCK_REF]: {
    group: NODE_GROUPS.SQL_EXPRESSION,
    inline: true,
    selectable: true,
    atom: true,
    htmlTagName: 'span',
    attributesDef: {
      pageId: { datatype: 'STRING', default: '' },
      sqlId: { datatype: 'STRING' },
      omitEncloseBracket: { datatype: 'BOOLEAN', default: false },
      withAlias: { datatype: 'BOOLEAN', default: false },
      overwriteParams: { datatype: 'JSON', default: [] },
    },
  },
  [NODE_NAMES.SQL_BLOCK_RESULT]: {
    content: `${NODE_GROUPS.SQL_JOB_RESULT_VIEW}*`,
    marks: '',
    attributesDef: {
      jobId: { datatype: 'STRING' },
      state: { datatype: 'STRING' },
      cached: { datatype: 'BOOLEAN' },
      errorMessage: { datatype: 'STRING' },
      executedAt: { datatype: 'NUMBER' },
    },
  },
  [NODE_NAMES.SQL_BLOCK_RESULT_TABLE]: {
    content: '',
    atom: true,
    marks: '',
    group: NODE_GROUPS.SQL_JOB_RESULT_VIEW,
    attributesDef: {
      height: { datatype: 'STRING', default: '300px' },
    },
  },
  [NODE_NAMES.SQL_BLOCK_RESULT_CHART]: {
    content: '',
    atom: true,
    marks: '',
    group: NODE_GROUPS.SQL_JOB_RESULT_VIEW,
    attributesDef: {
      chartId: { datatype: 'STRING' },
      chartSettings: { datatype: 'JSON' },
      chartJobId: { datatype: 'STRING' },
      chartJobQueryHash: { datatype: 'STRING' },
      height: { datatype: 'STRING', default: '300px' },
    },
  },
  [NODE_NAMES.SQL_BLOCK_RESULT_STATS]: {
    content: '',
    atom: true,
    marks: '',
    group: NODE_GROUPS.SQL_JOB_RESULT_VIEW,
    attributesDef: {
      statsJobId: { datatype: 'STRING' },
      errorMessage: { datatype: 'STRING' },
      openFields: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.SQL_TABLE_REF]: {
    group: NODE_GROUPS.SQL_EXPRESSION,
    inline: true,
    selectable: true,
    atom: true,
    htmlTagName: 'span',
    attributesDef: {
      tableResourceId: { datatype: 'STRING' },
      withAlias: { datatype: 'BOOLEAN', default: false },
    },
  },
  [NODE_NAMES.SQL_QUERY_REF]: {
    group: NODE_GROUPS.SQL_EXPRESSION,
    inline: true,
    selectable: true,
    atom: true,
    htmlTagName: 'span',
    attributesDef: {
      queryId: { datatype: 'STRING' },
      queryJson: { datatype: 'JSON' },
      omitEncloseBracket: { datatype: 'BOOLEAN', default: false },
    },
  },
  [NODE_NAMES.SQL_PARAM_REF]: {
    group: NODE_GROUPS.SQL_EXPRESSION,
    inline: true,
    selectable: true,
    atom: true,
    htmlTagName: 'span',
    attributesDef: {
      refParamKey: { datatype: 'STRING' },
      escapeType: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.BLOCK_CHART]: {
    content: '',
    atom: true,
    marks: '',
    group: 'block',
    isolating: true,
    attributesDef: {
      chartId: { datatype: 'STRING' },
      sqlId: { datatype: 'STRING' },
      chartSettings: { datatype: 'JSON' },
      chartJobId: { datatype: 'STRING' },
      chartJobQueryHash: { datatype: 'STRING' },
      height: { datatype: 'STRING', default: '300px' },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.INLINE_CHART]: {
    content: '',
    atom: true,
    marks: '',
    inline: true,
    htmlTagName: 'span',
    group: NODE_GROUPS.INLINE,
    attributesDef: {
      chartId: { datatype: 'STRING' },
      sqlId: { datatype: 'STRING' },
      chartSettings: { datatype: 'JSON' },
      chartJobId: { datatype: 'STRING' },
      chartJobQueryHash: { datatype: 'STRING' },
      width: { datatype: 'STRING', default: '300px' },
      height: { datatype: 'STRING', default: '300px' },
    },
  },
  [NODE_NAMES.SQL_RESULT_SCALAR]: {
    content: '',
    atom: true,
    marks: '',
    inline: true,
    htmlTagName: 'span',
    group: NODE_GROUPS.INLINE,
    attributesDef: {
      resultScalarId: { datatype: 'STRING' },
      sqlId: { datatype: 'STRING' },
      field: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.IMAGE_BLOCK]: {
    content: '',
    atom: true,
    marks: '',
    inline: true,
    htmlTagName: 'span',
    group: NODE_GROUPS.INLINE,
    attributesDef: {
      state: { datatype: 'STRING' },
      path: { datatype: 'STRING' },
      errorMessage: { datatype: 'STRING' },
      uploadKey: { datatype: 'STRING' },
      publicUrl: { datatype: 'STRING' },
      width: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.VIDEO_BLOCK]: {
    content: '',
    atom: true,
    marks: '',
    inline: true,
    htmlTagName: 'span',
    group: NODE_GROUPS.INLINE,
    attributesDef: {
      state: { datatype: 'STRING' },
      path: { datatype: 'STRING' },
      errorMessage: { datatype: 'STRING' },
      uploadKey: { datatype: 'STRING' },
      publicUrl: { datatype: 'STRING' },
      width: { datatype: 'STRING' },
      height: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.TABLE_VIEWER_BLOCK]: {
    marks: '',
    group: 'block',
    isolating: true, // gapcursor 使うために必要
    attributesDef: {
      tableResourceId: { datatype: 'STRING' },
      maxRows: { datatype: 'NUMBER' },
      height: { datatype: 'STRING', default: '300px' },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.MERMAID_BLOCK]: {
    content: 'text*',
    marks: '',
    group: 'block',
    code: true,
    defining: true,
    isolating: true, // gapcursor 使うために必要
    attributesDef: {
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.BLOCK_EQUATION]: {
    content: 'text*',
    marks: '',
    group: 'block',
    code: true,
    isolating: true, // gapcursor 使うために必要
    attributesDef: {
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.INLINE_EQUATION]: {
    content: '',
    marks: '',
    inline: true,
    htmlTagName: 'span',
    selectable: true,
    group: NODE_GROUPS.INLINE,
    attributesDef: {
      equation: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.CALLOUT_BLOCK]: {
    content: `(${NODE_NAMES.PARAGRAPH} | ${NODE_NAMES.LIST})+`,
    marks: '',
    group: 'block',
    defining: true,
    isolating: true,
    attributesDef: {
      kind: { datatype: 'STRING' },
      customIcon: { datatype: 'STRING' },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.OEMBED_BLOCK]: {
    content: '',
    atom: true,
    marks: '',
    group: 'block',
    attributesDef: {
      url: { datatype: 'STRING' },
      oEmbedData: { datatype: 'STRING' },
      linkId: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.NOTEBOOK_LINK]: {
    content: '',
    atom: true,
    marks: '',
    inline: true,
    htmlTagName: 'span',
    group: NODE_GROUPS.INLINE,
    attributesDef: {
      notebookId: { datatype: 'STRING', default: '' },
      pageId: { datatype: 'STRING' },
      urlHash: { datatype: 'STRING' },
    },
  },
  [NODE_NAMES.MENTION]: {
    // TODO: 定義まとめたい
    group: NODE_GROUPS.INLINE,
    inline: true,
    selectable: false,
    atom: true,
    htmlTagName: 'span',
    attributesDef: {
      mentionId: { datatype: 'STRING' },
      accountId: { datatype: 'STRING' },
      username: { datatype: 'STRING' },
      linkId: { datatype: 'STRING' },
    },
  },
};

export const SQL_BLOCK_BODY_CONTENT_NODE_NAMES = [
  NODE_NAMES.TEXT,
  NODE_NAMES.SQL_BLOCK_REF,
  NODE_NAMES.SQL_TABLE_REF,
  NODE_NAMES.SQL_QUERY_REF,
  NODE_NAMES.SQL_PARAM_REF,
] as const;

export const BLOCK_GROUP_NODE_NAMES = [
  NODE_NAMES.PARAGRAPH,
  NODE_NAMES.HEADING,
  NODE_NAMES.BLOCKQUOTE,
  NODE_NAMES.LIST,
  NODE_NAMES.HORIZONTAL_RULE,
  NODE_NAMES.TABLE,
  NODE_NAMES.SQL_BLOCK,
  NODE_NAMES.BLOCK_CHART,
  NODE_NAMES.TABLE_VIEWER_BLOCK,
  NODE_NAMES.CALLOUT_BLOCK,
  NODE_NAMES.OEMBED_BLOCK,
] as const;

export const INLINE_GROUP_NODE_NAMES = [
  NODE_NAMES.TEXT,
  NODE_NAMES.IMAGE_BLOCK,
  NODE_NAMES.VIDEO_BLOCK,
  NODE_NAMES.INLINE_CHART,
  NODE_NAMES.SQL_RESULT_SCALAR,
  NODE_NAMES.INLINE_EQUATION,
  NODE_NAMES.NOTEBOOK_LINK,
  NODE_NAMES.MENTION,
] as const;

export const LINKABLE_NODE_NAMES = (() => {
  const nodeNames: NodeName[] = [];
  Object.entries(NODE_DEFS).forEach(([nodeName, nodeDef]) => {
    if (nodeDef?.attributesDef?.linkId) {
      nodeNames.push(nodeName as NodeName);
    }
  });
  return nodeNames;
})();

export const ISOLATED_SEARCHABLE_NODE_NAMES = [
  NODE_NAMES.SQL_BLOCK_BODY,
  NODE_NAMES.CODE_BLOCK,
  NODE_NAMES.MERMAID_BLOCK,
] as const;

export const EXTENSION_NAMES = {
  SQL_BUILDER: 'sqlBuilder',
  SQL_EXECUTOR: 'sqlExecutor',
  CODE_EDITOR_SHORTCUTS: 'codeEditorShortcuts',
  SQL_SYNTAX_HIGLIGHT: 'sqlSyntaxHighlight',
  SQL_AUTOCOMPLETER: 'sqlAutocompleter',
  SUGGESTION_SUGGESTION: 'suggestionSuggestion',
  SUGGESTION_TABLE_REF: 'suggestionTableRef',
  SUGGESTION_QUERY_REF: 'suggestionQueryRef',
  SUGGESTION_PARAM_REF: 'suggestionParamRef',
  SUGGESTION_SQL_BLOCK_REF: 'suggestionSqlBlockRef',
  SUGGESTION_ARG_REF: 'suggestionArgRef',
  SLASH_COMMAND: 'slashCommand',
  SQL_BLOCK_SLASH_COMMAND: 'sqlBlockSlashCommand',
  CHART_SLASH_COMMAND: 'chartSlashCommand',
  TABLE_VIEWER_CONNECTION_SLASH_COMMAND: 'tableViewerConnectionSlashCommand',
  NOTEBOOK_LINK_SLASH_COMMAND: 'notebookLinkSlashCommand',
  COMMON_COMMANDS: 'commonCommands',
  EDITOR_EVENT_EMITTER: 'editorEventEmitter',
  TRANSFORM_PASTED: 'transformPasted',
  SEARCH_AND_REPLACE: 'searchAndReplace',
  MEDIA_STORE: 'mediaStore',
  MOUSE_HOVER_MENU: 'mouseHoverMenu',
  JUMP_TO: 'jumpTo',
  DEBUGGER: 'debugger',
  EMOJI_PICKER: 'emojiPicker',
  SQL_RESULT_SCALAR_PICKER: 'sqlResultScalarPicker',
  COMMENT_THREAD: 'commentThread',
  EXPLORER_MENU: 'explorerMenu',
  CODE_SNIPPET: 'codeSnippet',
  MERMAID_BUILDER: 'mermaidBuilder',
  BLOCK_EQUATION_EDITOR: 'blockEquationEditor',
  CODE_MARK: 'codeMark',
  COLLABORATION_LOADING_STATE: 'collaborationLoadingState',
  FULLSCREEN_MODE: 'fullscreenMode',
  AI_SUGGESTION: 'aiSuggestion',
} as const;

export const TEXT_MARK_NAMES = [
  MARK_NAMES.BOLD,
  MARK_NAMES.CODE,
  MARK_NAMES.HIGHLIGHT,
  MARK_NAMES.ITALIC,
  MARK_NAMES.LINK,
  MARK_NAMES.STRIKE,
  MARK_NAMES.UNDERLINE,
  MARK_NAMES.COMMENT,
] as const;

export const MARK_DEFS: {
  [markName in MarkName]: {
    attributesDef?: { [key: string]: NodeAttributeDef };
  };
} = {
  [MARK_NAMES.BOLD]: {},
  [MARK_NAMES.CODE]: {},
  [MARK_NAMES.HIGHLIGHT]: {},
  [MARK_NAMES.ITALIC]: {},
  [MARK_NAMES.LINK]: {
    attributesDef: {
      href: { datatype: 'STRING' },
      target: { datatype: 'STRING' },
      rel: { datatype: 'STRING' },
      class: { datatype: 'STRING' },
    },
  },
  [MARK_NAMES.STRIKE]: {},
  [MARK_NAMES.UNDERLINE]: {},
  [MARK_NAMES.COMMENT]: {
    attributesDef: {
      commentThreadId: { datatype: 'STRING' },
    },
  },
};

export const SQL_RESOLVE_ERROR_CODES = {
  INVALID_CONTENT: 'INVALID_CONTENT', // NodeJson が不正
  EMPTY_STRING: 'EMPTY_STRING', // SQLが空文字列
  LOOP_DETECTED: 'LOOP_DETECTED', // 参照がループしている
  REF_SQL_NOT_FOUND: 'REF_SQL_NOT_FOUND', // 参照先のSQLが見つからない
  REF_SQL_ERROR: 'REF_SQL_ERROR', // reference先がエラー
  REF_PARAM_NOT_FOUND: 'REF_PARAM_NOT_FOUND', // reference先のパラメータが見つからない
  REF_PARAM_INVALID_VALUE: 'REF_PARAM_INVALID_VALUE', // reference先のパラメータが不正な値
  CONN_NOT_FOUND: 'CONN_NOT_FOUND', // connection が見つからない・権限がない
} as const;

export const SQL_RESOLVE_ERROR_MESSAGES: {
  [key in keyof typeof SQL_RESOLVE_ERROR_CODES]: string;
} = {
  INVALID_CONTENT: 'Failed to generate SQL', // どちらかというコード起因のはずなので
  EMPTY_STRING: 'SQL not specified',
  LOOP_DETECTED: 'SQL circular reference detected',
  REF_SQL_NOT_FOUND: 'Referenced SQL not found',
  REF_SQL_ERROR: 'Error found in referenced SQL',
  REF_PARAM_NOT_FOUND: 'Referenced parameter not found',
  REF_PARAM_INVALID_VALUE: 'Referenced parameter has invalid value',
  CONN_NOT_FOUND: 'Connection not found(Unauthorized or deleted)',
} as const;

export const SQL_BLOCK_RESULT_STATE = {
  WAITING: 'WAITING',
  DISPATCHED: 'DISPATCHED',
  ERROR: 'ERROR',
  REMOVED: 'REMOVED',
} as const;

export const CHART_EXEC_STATES = {
  RUNNING: 'RUNNING',
  CHANGED: 'CHANGED',
  UNMOUNTED: 'UNMOUNTED',
  OTHERS: 'OTHERS',
} as const;

export const NOTEBOOK_PAGE_KINDS = {
  DEFAULT: 'DEFAULT',
  ELASTIC_GRID: 'ELASTIC_GRID',
  DIVIDER: 'DIVIDER',
} as const;

export const NOTEBOOK_ELASTIC_GRID_ITEM_KINDS = {
  SQL_BLOCK_RESULT: 'SQL_BLOCK_RESULT',
  CHART: 'CHART',
  HEADING: 'HEADING',
  RICH_TEXT: 'RICH_TEXT',
} as const;

export const NOTEBOOK_ELASTIC_GRID_ITEM_HEADING_LEVELS = [1, 2, 3] as const;

export const BUILTIN_COMPONENTS = [
  {
    kind: NOTEBOOK_ELASTIC_GRID_ITEM_KINDS.HEADING,
    icon: 'heading',
    label: 'Heading',
  },
  {
    kind: NOTEBOOK_ELASTIC_GRID_ITEM_KINDS.RICH_TEXT,
    icon: 'text',
    label: 'Text',
  },
];

export const NOTEBOOK_ELASTIC_GRID_NODE_TYPES = {
  LEAF: 'leaf',
  COLUMN: 'column',
  ROW: 'row',
} as const;

export const NOTEBOOK_DEFAULT_PAGE_BODY: RootNodeJson = {
  type: 'doc',
  content: [{ type: 'heading', attrs: { level: 1 } }, { type: 'paragraph' }],
};

// ここに来たものは最初のページに replace する
export const NOTEBOOK_EACH_PAGE_TOP = 'top';
// 最後に閲覧したページが localStorage にあれば、そのページに遷移する
export const NOTEBOOK_EACH_PAGE_RECENT = 'recent';
// 検索結果から遷移する時にnotebook内のどのページを表示するかを検索ワードを元に決めたい時に使う
export const NOTEBOOK_EACH_PAGE_SEARCHED = 'searched';

export const NOTEBOOK_JOB_STATE = {
  PENDING: 'PENDING', // metadata.status.state === "PENDING"
  RUNNING: 'RUNNING', // metadata.status.state === "RUNNING"
  SUCCESS: 'SUCCESS', // metadata.status.state === "DONE" && !metadata.status.errorResult
  ERROR: 'ERROR', // metadata.status.state === "DONE" && !!metadata.status.errorResult
} as const;

export const NOTEBOOK_JOB_EXPIRED_ERROR_MESSAGE = 'Job is expired';

// JobHistories で表示するジョブ実行履歴の上限件数
export const MAX_HISTORY_SIZE_DISPLAY = 30;

// SqlBlock の実行結果でフロント側に持ってくる上限件数
export const MAX_RESULT_ROWS_DISPLAY = 1000;

// TableViewerBlock の実行結果でフロント側に持ってくる上限件数
export const MAX_PREVIEW_ROWS_DISPLAY = 1000;

// SqlParameter の選択肢として取得する SQL 実行結果の上限件数
export const MAX_SELECT_OPTION_ROWS = 1000;

// 生成する SignedURL の expire までの秒数
export const NOTEBOOK_MEDIA_SIGNED_URL_TTL = 60 * 10;

//  カーソルの表示のためのマージン (これを取らないとカーソルを横に表示できず カーソルがページ最上段に飛んでしまう)
export const NOTEBOOK_INLINE_RESIZABLE_WIDTH_MARGIN = 18;

// ImageBlock のサイズ周り
export const NOTEBOOK_IMAGE_BLOCK_MIN_WIDTH = 40;
export const NOTEBOOK_IMAGE_BLOCK_RESIZE_STEP = 30;

// oEmbedBlock のサイズ周り
export const NOTEBOOK_OEMBED_BLOCK_MAX_WIDTH = 640;
export const NOTEBOOK_OEMBED_BLOCK_MAX_HEIGHT = 640;

// URL生成時に使うハッシュのprefix
export const LINK_HASH_LINK_PREFIX = `link:`;
export const LINK_HASH_THREAD_PREFIX = `thread:`;
export const LINK_HASH_MENTION_PREFIX = `mention:`;
export const LINK_HASH_SQL_PREFIX = 'sql:';
export const LINK_HASH_CHART_PREFIX = 'chart:';
export const LINK_HASH_RESULT_SCALAR_PREFIX = 'scalar:';
export const LINK_HASH_GRIDITEM_PREFIX = 'griditem:';
export const LINK_HASH_TEXT_PREFIX = 'text:';

export const CODE_SNIPPET_CURSOR_PLACEHOLDER = '$$';
export const MAX_CODE_SNIPPET_COUNT = 100;

export const CALLOUT_KINDS = {
  INFO: 'info',
  SUCCESS: 'success',
  WARN: 'warn',
  DANGER: 'danger',
} as const;

// SVG はセキュリティリスクの関係で入れていない
export const ALLOWED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/tiff',
];
export const ALLOWED_VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/quicktime',
  'video/x-msvideo',
  'video/mpeg',
  'video/3gpp',
  'video/3gpp2',
];

/**
 * oEmbed の Provider で使いそうなものだけとりあえず登録
 * TODO: 動作確認しつつニーズがありそうなものを追加する
 * https://oembed.com/providers.json
 *
 * Providers and consumers are strongly encouraged to use the discovery mechanism
 * とあるので、どこかのタイミングでホワイトリスト式を辞めても良いかも
 */
export const OEMBED_PROVIDERS: {
  provider_name: string;
  provider_url: string;
  endpoints: { schemes: string[]; url: string; discovery?: boolean }[];
}[] = [
  {
    provider_name: 'CodePen',
    provider_url: 'https://codepen.io',
    endpoints: [
      {
        schemes: ['http://codepen.io/*', 'https://codepen.io/*'],
        url: 'https://codepen.io/api/oembed',
      },
    ],
  },
  {
    provider_name: 'YouTube',
    provider_url: 'https://www.youtube.com/',
    endpoints: [
      {
        schemes: [
          'https://*.youtube.com/watch*',
          'https://*.youtube.com/v/*',
          'https://youtu.be/*',
          'https://*.youtube.com/playlist?list=*',
          'https://youtube.com/playlist?list=*',
          'https://*.youtube.com/shorts*',
        ],
        url: 'https://www.youtube.com/oembed',
        discovery: true,
      },
    ],
  },
  {
    provider_name: 'Twitter',
    provider_url: 'http://www.twitter.com/',
    endpoints: [
      {
        schemes: [
          'https://twitter.com/*',
          'https://twitter.com/*/status/*',
          'https://*.twitter.com/*/status/*',
        ],
        url: 'https://publish.twitter.com/oembed',
      },
    ],
  },
  {
    provider_name: 'GIPHY',
    provider_url: 'https://giphy.com',
    endpoints: [
      {
        schemes: [
          'https://giphy.com/gifs/*',
          'https://giphy.com/clips/*',
          'http://gph.is/*',
          'https://media.giphy.com/media/*/giphy.gif',
        ],
        url: 'https://giphy.com/services/oembed',
        discovery: true,
      },
    ],
  },
  {
    provider_name: 'Canva',
    provider_url: 'https://www.canva.com',
    endpoints: [
      {
        schemes: ['https://www.canva.com/design/*/view'],
        url: 'https://www.canva.com/_oembed',
        discovery: true,
      },
    ],
  },
  {
    provider_name: 'Flickr',
    provider_url: 'https://www.flickr.com/',
    endpoints: [
      {
        schemes: [
          'http://*.flickr.com/photos/*',
          'http://flic.kr/p/*',
          'https://*.flickr.com/photos/*',
          'https://flic.kr/p/*',
          'https://*.*.flickr.com/*/*',
          'http://*.*.flickr.com/*/*',
        ],
        url: 'https://www.flickr.com/services/oembed/',
        discovery: true,
      },
    ],
  },
];

// Pageの横幅の表示制御パターン
export const NOTEBOOK_PAGE_WIDTH_TYPES = {
  AUTO: 'AUTO', // 画面に合わせて伸縮
  FIXED: 'FIXED', // 固定
  RANGE: 'RANGE', // 最小最大を指定
} as const;

export const NOTEBOOK_PAGE_WIDTH_PIXEL_CHOICES = [640, 800, 1024, 1280, 1366, 1600] as const;

export const NOTEBOOK_LOCK_MODES = {
  ALL_LOCKED: 'ALL_LOCKED',
  QUERY_ONLY: 'QUERY_ONLY',
  UNLOCKED: 'UNLOCKED',
} as const;

export const NOTEBOOK_SHARE_TYPES = {
  ACCOUNT: 'ACCOUNT',
  GROUP: 'GROUP',
} as const;

export const NOTEBOOK_SHARED_ROLES = {
  EDITOR: 'notebook.editor',
  VIEWER: 'notebook.viewer',
} as const;

export const NOTEBOOK_AUTHZ_ACTIONS = {
  READ: 'notebook.read',
  WRITE: 'notebook.write',
  SHARE: 'notebook.share',
  PUBLISH: 'notebook.publish',
} as const;

export const CONNECTION_AUTHZ_ACTIONS = {
  READ: 'connection.read',
  EXECUTE: 'connection.execute',
} as const;

export const NOTEBOOK_ROLE_TO_ACTIONS_MAP: { [role in NotebookSharedRole]: NotebookAuthzAction[] } =
  {
    [NOTEBOOK_SHARED_ROLES.EDITOR]: [NOTEBOOK_AUTHZ_ACTIONS.READ, NOTEBOOK_AUTHZ_ACTIONS.WRITE],
    [NOTEBOOK_SHARED_ROLES.VIEWER]: [NOTEBOOK_AUTHZ_ACTIONS.READ],
  };

// NOTEBOOK_LOCK_MODES とユーザの権限から決定
export const NOTEBOOK_EDIT_PERMISSIONS = {
  READ_ONLY: 'READ_ONLY',
  QUERY_ONLY: 'QUERY_ONLY',
  EDIT_ALL: 'EDIT_ALL',
} as const;

export const NOTEBOOK_RESOURCE_TYPES = {
  DEFAULT_API: 'DEFAULT_API',
  SNAPSHOT_API: 'SNAPSHOT_API',
  GLOBAL: 'GLOBAL',
  INTEGRATED: 'INTEGRATED',
  COMPONENT_TEST: 'COMPONENT_TEST',
} as const;

export const LS_PREFIX_NOTEBOOK = 'cd.Notebook.';

export const NOTEBOOK_GRID_ITEM_SCROLL_ANCHOR_PREFIX = 'GridItemScrollAnchor_';

export const NOTEBOOK_CUSTOM_LINK_CLASS = 'internal-link';

export const GRID_PAGE_DEFAULT_TITLE = 'Grid';

export const PARTIAL_CACHE_USED_STRING = 'PARTICAL-CACHE-REPLACED';

export const UNTITLED_PAGE_NAME = 'Untitled page';
export const UNTITLED_HEADING = 'Untitled heading';
export const UNTITLED_SQL_BLOCK_NAME = 'Untitled SQL';
export const UNTITLED_SECTION_DIVIDER = 'Untitled section';
export const UNTITLED_PARAM_NAME = 'Untitled parameter';

export const TOOLTIP_CONTAINER_INJECT_KEY = 'NotebookTooltipContainer';
export const IS_FULLSCREEN_NOTEBOOK_INJECT_KEY = 'IsFullscreenNotebook';

export const TABLE_VIEWER_DEFAULT_ROWS = 20;

export const AWARENESS_USER_FIELD = 'user';
export const AWARENESS_VISIBILITY_FIELD = 'visibility';
export const AWARENESS_VISIBILITY_VALUES = {
  VISIBLE: 'v',
  HIDDEN: 'h',
} as const;
