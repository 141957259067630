import type { App } from 'vue';
import type { Router } from 'vue-router';

import * as Sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import { ApiError } from '@cdm/@shared-server-notebook/endpoints/client';
import { GlobalConfig } from '@cdm/configs';

const sentryEnabled = GlobalConfig.SENTRY_DSN !== '';

// MEMO: パフォーマンスへの影響を見るために disable できるようにする（開発者向け）
const getSentryDisabled = () => localStorage.getItem('sentryDisabled') === 'true';

const modifyEventTitle = <E extends Sentry.Event>(event: E) => {
  if (!event.exception?.values || event.exception.values?.length === 0) {
    return event;
  }
  const exception = event.exception.values.map(e => {
    const newType = e.value;
    const newValue = e.type;
    e.type = newType;
    e.value = newValue;
    return e;
  });
  event.exception.values = exception;
  return event;
};

export function initLogger(app: App, router: Router) {
  if (!sentryEnabled) return;
  if (getSentryDisabled()) {
    console.warn('Sentry is disabled by localStorage flag.');
    return;
  }

  Sentry.init({
    app,
    dsn: GlobalConfig.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracePropagationTargets: ['localhost', 'api.cdm.test', 'api.evacdm.com', 'api.codatum.com'],
    // replaysSessionSampleRate: 0.0,
    // replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    // https://github.com/codatum/cdm/issues/2454
    // MEMO: 重いので一旦無効化（入れるなら対象コンポーネントを絞ったほうが良さそう）
    trackComponents: false,
    environment: GlobalConfig.ENVIRONMENT,
    beforeSend: (event, _hint) => {
      return modifyEventTitle(event);
    },
    beforeSendTransaction: (event, _hint) => {
      return modifyEventTitle(event);
    },
  });

  // app.config.errorHandler で捕捉できないエラーを収集
  window.addEventListener('error', err => {
    logError(err);
  });
  window.addEventListener('unhandledrejection', event => {
    console.error('unhandledrejection', event);
  });
}

export function setUserInContext(userId: string) {
  if (!sentryEnabled) return;
  Sentry.setUser({ id: userId });
  datadogRum.setUser({ id: userId });
}

export function setTagInContext(key: string, value: string) {
  if (!sentryEnabled) return;
  Sentry.setTag(key, value);
  datadogRum.addRumGlobalContext(key, value);
}

export function setExtraInContext(key: string, value: string) {
  if (!sentryEnabled) return;
  Sentry.setExtra(key, value);
}

type SentryTag = {
  errorId?: string;
  reqId?: string;
};

/** sentry にエラーを送信する用の関数 */
export function logError(error: any) {
  if (!sentryEnabled) return;

  const errorId = crypto.randomUUID ? crypto.randomUUID() : `err-${Date.now()}-${Math.random()}}`;
  console.error(`Error ID: ${errorId}`);
  console.error(error);
  const tags: SentryTag = { errorId };
  if (error instanceof ApiError) tags.reqId = error.reqId;
  Sentry.captureException(error, { tags });
}

export const DEBUG_LOG_NAMES = {
  GRID_STORE: 'GRID_STORE',
  GRID_JOB: 'GRID_JOB',
  NOTEBOOK_EDITOR: 'NOTEBOOK_EDITOR',
  S2_TABLE: 'S2_TABLE',
} as const;

type DebugLogName = typeof DEBUG_LOG_NAMES[keyof typeof DEBUG_LOG_NAMES];

// MEMO: 開発時に出力したいログをここで設定する
const ENABLED_DEBUG_LOGS: { [key in DebugLogName]: boolean } = {
  GRID_STORE: false,
  GRID_JOB: false,
  NOTEBOOK_EDITOR: false,
  S2_TABLE: false,
};

export const getDebugLogger =
  (logName: DebugLogName, options: { enableOnProd?: boolean; force?: boolean } = {}) =>
  (...args: any[]) => {
    if (!options.enableOnProd && import.meta.env.PROD) return;
    if (!ENABLED_DEBUG_LOGS[logName] && !options.force) return;
    console.debug(`[${logName}]`, ...args);
  };
