import { BaseClient } from "./common";
import type { 
  GetWorkspaceRequest,
  GetWorkspaceResponse,
  GetWorkspaceDetailRequest,
  GetWorkspaceDetailResponse,
  UpdateWorkspaceRequest,
  UpdateWorkspaceResponse,
  DeleteWorkspaceRequest,
  DeleteWorkspaceResponse,
  UpdateWorkspaceIconRequest,
  UpdateWorkspaceIconResponse,
  DeleteWorkspaceIconRequest,
  DeleteWorkspaceIconResponse,
  GetInviteLinkRequest,
  GetInviteLinkResponse,
  RegenerateInviteLinkRequest,
  RegenerateInviteLinkResponse,
  GetUserPermissionsRequest,
  GetUserPermissionsResponse,
  ExplainPermissionsRequest,
  ExplainPermissionsResponse,
  GetCustomerRequest,
  GetCustomerResponse,
  ListInvoicesRequest,
  ListInvoicesResponse,
  CreateBillingPortalSessionRequest,
  CreateBillingPortalSessionResponse,
  CreateUpdateSubscriptionSessionRequest,
  CreateUpdateSubscriptionSessionResponse,
  UpdateSubscriptionForTrialRequest,
  UpdateSubscriptionForTrialResponse,
  UpdateSubscriptionRequest,
  UpdateSubscriptionResponse,
  GetWorkspaceAccountsRequest,
  GetWorkspaceAccountsResponse,
  GetWorkspaceAccountsByIdsRequest,
  GetWorkspaceAccountsByIdsResponse,
  UpdateWorkspaceAccountRequest,
  UpdateWorkspaceAccountResponse,
  DeleteWorkspaceAccountRequest,
  DeleteWorkspaceAccountResponse,
  JoinAccountGroupHandlerRequest,
  JoinAccountGroupHandlerResponse,
  LeaveAccountGroupHandlerRequest,
  LeaveAccountGroupHandlerResponse,
  FindPermittedIndividualsByObjectRequest,
  FindPermittedIndividualsByObjectResponse,
  EmptyRequest,
  EmptyResponse,
  InviteUserToWorkspaceRequest,
  InviteUserToWorkspaceResponse,
  GetWorkspaceInvitationsRequest,
  GetWorkspaceInvitationsResponse,
  DeleteWorkspaceInvitationRequest,
  DeleteWorkspaceInvitationResponse,
  ResendWorkspaceInvitationRequest,
  ResendWorkspaceInvitationResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  ToggleUnreadRequest,
  ToggleUnreadResponse,
  MarkAllAsReadRequest,
  MarkAllAsReadResponse,
  HasUnreadRequest,
  HasUnreadResponse,
  GetNotifSettingRequest,
  NotifSettingValues,
  UpsertNotifSettingRequest,
  FindGroupsRequest,
  FindGroupsResponse,
  GetGroupPermissionsForObjRequest,
  GetGroupPermissionsForObjResponse,
  CreateGroupRequest,
  CreateGroupResponse,
  RenameGroupRequest,
  RenameGroupResponse,
  UpdateGroupIconRequest,
  UpdateGroupIconResponse,
  DeleteGroupIconRequest,
  DeleteGroupIconResponse,
  DeleteGroupRequest,
  DeleteGroupResponse,
  AddGroupOwnerRequest,
  AddGroupOwnerResponse,
  RemoveGroupOwnerRequest,
  RemoveGroupOwnerResponse,
  SearchAuditLogEntriesRequest,
  SearchAuditLogEntriesResponse,
  FindApiKeysRequest,
  FindApiKeysResponse,
  CreateApiKeyRequest,
  CreateApiKeyResponse,
  RenameApiKeyRequest,
  RenameApiKeyResponse,
  DeleteApiKeyRequest,
  DeleteApiKeyResponse,
  GetPlainSecretRequest,
  GetPlainSecretResponse,
  AppendNewSecretRequest,
  AppendNewSecretResponse,
  RemoveSecretVersionRequest,
  RemoveSecretVersionResponse,
  UpdateAccountRequest,
  UpdateAccountResponse,
  UpdateAccountProfileImageRequest,
  UpdateAccountProfileImageResponse,
  UpdateAccountProfileImageFromGravatarRequest,
  UpdateAccountProfileImageFromGravatarResponse,
  DeleteAccountProfileImageRequest,
  DeleteAccountProfileImageResponse,
  UpdatePreferencesRequest,
  UpdatePreferencesResponse,
  UpdateAccountEmailRequest,
  UpdateAccountEmailResponse,
  DoneOnboardingRequest,
  DoneOnboardingResponse,
  GetIpCountryRequest,
  GetIpCountryResponse,
  GetAccountSettingRequest,
  GetAccountSettingResponse,
  UpdateKeyboardShortcutsRequest,
  UpdateKeyboardShortcutsResponse,
  CheckValidCodeRequest,
  CheckValidCodeResponse,
  PostConnectionRequestFormRequest,
  PostConnectionRequestFormResponse,
  GetWorkspacesRequest,
  GetWorkspacesResponse,
  UploadWorkspaceIconRequest,
  UploadWorkspaceIconResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  GetInvitedWorkspacesRequest,
  GetInvitedWorkspacesResponse,
  GetJoinableWorkspacesRequest,
  GetJoinableWorkspacesResponse,
  JoinWorkspaceRequest,
  JoinWorkspaceResponse,
  JoinWorkspaceViaInviteLinkRequest,
  JoinWorkspaceViaInviteLinkResponse,
  ActivateWorkspaceInvitationRequest,
  ActivateWorkspaceInvitationResponse,
  DeclineWorkspaceInvitationRequest,
  DeclineWorkspaceInvitationResponse,
  GetNotificationCountsRequest,
  GetNotificationCountsResponse,
  CreateWorkspaceForAdminRequest,
  CreateWorkspaceForAdminResponse,
  UpdateWorkspaceForAdminRequest,
  UpdateWorkspaceForAdminResponse,
  DeleteWorkspaceForAdminRequest,
  DeleteWorkspaceForAdminResponse,
  GetFeaturesAndLimitsForAdminRequest,
  GetFeaturesAndLimitsForAdminResponse,
  FindAllWorkspacesForAdminRequest,
  FindAllWorkspacesForAdminResponse,
  GetWorkspaceDetailForAdminRequest,
  GetWorkspaceDetailForAdminResponse,
  FindAllAccountsByIdsForAdminRequest,
  FindAllAccountsByIdsForAdminResponse,
  SimulateErrorRequest,
  SimulateErrorResponse,
  InvokeInternalEventRequest,
  InvokeInternalEventResponse,
} from "./shared-types";

export * from "./shared-types";

export class ApiClient extends BaseClient {
  
  async workspace_get(requestData: GetWorkspaceRequest, query: {ws: string} ): Promise<GetWorkspaceResponse> {
    return await this.fetch("/account/api/ws/workspace/get", requestData, query, {});
  }
  
  async workspace_getDetail(requestData: GetWorkspaceDetailRequest, query: {ws: string} ): Promise<GetWorkspaceDetailResponse> {
    return await this.fetch("/account/api/ws/workspace/getDetail", requestData, query, {});
  }
  
  async workspace_update(requestData: UpdateWorkspaceRequest, query: {ws: string} ): Promise<UpdateWorkspaceResponse> {
    return await this.fetch("/account/api/ws/workspace/update", requestData, query, {});
  }
  
  async workspace_delete(requestData: DeleteWorkspaceRequest, query: {ws: string} ): Promise<DeleteWorkspaceResponse> {
    return await this.fetch("/account/api/ws/workspace/delete", requestData, query, {});
  }
  
  async workspace_updateIcon(requestData: UpdateWorkspaceIconRequest, query: {ws: string} ): Promise<UpdateWorkspaceIconResponse> {
    return await this.fetch("/account/api/ws/workspace/updateIcon", requestData, query, {});
  }
  
  async workspace_deleteIcon(requestData: DeleteWorkspaceIconRequest, query: {ws: string} ): Promise<DeleteWorkspaceIconResponse> {
    return await this.fetch("/account/api/ws/workspace/deleteIcon", requestData, query, {});
  }
  
  async workspace_getInviteLink(requestData: GetInviteLinkRequest, query: {ws: string} ): Promise<GetInviteLinkResponse> {
    return await this.fetch("/account/api/ws/workspace/getInviteLink", requestData, query, {});
  }
  
  async workspace_regenerateInviteLink(requestData: RegenerateInviteLinkRequest, query: {ws: string} ): Promise<RegenerateInviteLinkResponse> {
    return await this.fetch("/account/api/ws/workspace/regenerateInviteLink", requestData, query, {});
  }
  
  async authorize_getUserPermissions(requestData: GetUserPermissionsRequest, query: {ws: string} ): Promise<GetUserPermissionsResponse> {
    return await this.fetch("/account/api/ws/authorize/getUserPermissions", requestData, query, {});
  }
  
  async authorize_explainPermissions(requestData: ExplainPermissionsRequest, query: {ws: string} ): Promise<ExplainPermissionsResponse> {
    return await this.fetch("/account/api/ws/authorize/explainPermissions", requestData, query, {});
  }
  
  async billing_getCustomer(requestData: GetCustomerRequest, query: {ws: string} ): Promise<GetCustomerResponse> {
    return await this.fetch("/account/api/ws/billing/getCustomer", requestData, query, {});
  }
  
  async billing_listInvoices(requestData: ListInvoicesRequest, query: {ws: string} ): Promise<ListInvoicesResponse> {
    return await this.fetch("/account/api/ws/billing/listInvoices", requestData, query, {});
  }
  
  async billing_createBillingPortalSession(requestData: CreateBillingPortalSessionRequest, query: {ws: string} ): Promise<CreateBillingPortalSessionResponse> {
    return await this.fetch("/account/api/ws/billing/createBillingPortalSession", requestData, query, {});
  }
  
  async billing_createUpdateSubscriptionSession(requestData: CreateUpdateSubscriptionSessionRequest, query: {ws: string} ): Promise<CreateUpdateSubscriptionSessionResponse> {
    return await this.fetch("/account/api/ws/billing/createUpdateSubscriptionSession", requestData, query, {});
  }
  
  async billing_updateSubscriptionForTrial(requestData: UpdateSubscriptionForTrialRequest, query: {ws: string} ): Promise<UpdateSubscriptionForTrialResponse> {
    return await this.fetch("/account/api/ws/billing/updateSubscriptionForTrial", requestData, query, {});
  }
  
  async billing_updateSubscription(requestData: UpdateSubscriptionRequest, query: {ws: string} ): Promise<UpdateSubscriptionResponse> {
    return await this.fetch("/account/api/ws/billing/updateSubscription", requestData, query, {});
  }
  
  async wsaccount_getMembers(requestData: GetWorkspaceAccountsRequest, query: {ws: string} ): Promise<GetWorkspaceAccountsResponse> {
    return await this.fetch("/account/api/ws/wsaccount/getMembers", requestData, query, {});
  }
  
  async wsaccount_getGuests(requestData: GetWorkspaceAccountsRequest, query: {ws: string} ): Promise<GetWorkspaceAccountsResponse> {
    return await this.fetch("/account/api/ws/wsaccount/getGuests", requestData, query, {});
  }
  
  async wsaccount_getAccountsByIds(requestData: GetWorkspaceAccountsByIdsRequest, query: {ws: string} ): Promise<GetWorkspaceAccountsByIdsResponse> {
    return await this.fetch("/account/api/ws/wsaccount/getAccountsByIds", requestData, query, {});
  }
  
  async wsaccount_updateAccount(requestData: UpdateWorkspaceAccountRequest, query: {ws: string} ): Promise<UpdateWorkspaceAccountResponse> {
    return await this.fetch("/account/api/ws/wsaccount/updateAccount", requestData, query, {});
  }
  
  async wsaccount_deleteAccount(requestData: DeleteWorkspaceAccountRequest, query: {ws: string} ): Promise<DeleteWorkspaceAccountResponse> {
    return await this.fetch("/account/api/ws/wsaccount/deleteAccount", requestData, query, {});
  }
  
  async wsaccount_joinAccountGroup(requestData: JoinAccountGroupHandlerRequest, query: {ws: string} ): Promise<JoinAccountGroupHandlerResponse> {
    return await this.fetch("/account/api/ws/wsaccount/joinAccountGroup", requestData, query, {});
  }
  
  async wsaccount_leaveAccountGroup(requestData: LeaveAccountGroupHandlerRequest, query: {ws: string} ): Promise<LeaveAccountGroupHandlerResponse> {
    return await this.fetch("/account/api/ws/wsaccount/leaveAccountGroup", requestData, query, {});
  }
  
  async wsaccount_findPermittedIndividualsByObject(requestData: FindPermittedIndividualsByObjectRequest, query: {ws: string} ): Promise<FindPermittedIndividualsByObjectResponse> {
    return await this.fetch("/account/api/ws/wsaccount/findPermittedIndividualsByObject", requestData, query, {});
  }
  
  async wsaccount_updateAccountLastActive(requestData: EmptyRequest, query: {ws: string} ): Promise<EmptyResponse> {
    return await this.fetch("/account/api/ws/wsaccount/updateAccountLastActive", requestData, query, {});
  }
  
  async wsinvitation_inviteAccount(requestData: InviteUserToWorkspaceRequest, query: {ws: string} ): Promise<InviteUserToWorkspaceResponse> {
    return await this.fetch("/account/api/ws/wsinvitation/inviteAccount", requestData, query, {});
  }
  
  async wsinvitation_getWorkspaceInvitations(requestData: GetWorkspaceInvitationsRequest, query: {ws: string} ): Promise<GetWorkspaceInvitationsResponse> {
    return await this.fetch("/account/api/ws/wsinvitation/getWorkspaceInvitations", requestData, query, {});
  }
  
  async wsinvitation_deleteWorkspaceInvitation(requestData: DeleteWorkspaceInvitationRequest, query: {ws: string} ): Promise<DeleteWorkspaceInvitationResponse> {
    return await this.fetch("/account/api/ws/wsinvitation/deleteWorkspaceInvitation", requestData, query, {});
  }
  
  async wsinvitation_resendWorkspaceInvitation(requestData: ResendWorkspaceInvitationRequest, query: {ws: string} ): Promise<ResendWorkspaceInvitationResponse> {
    return await this.fetch("/account/api/ws/wsinvitation/resendWorkspaceInvitation", requestData, query, {});
  }
  
  async notification_get(requestData: GetNotificationsRequest, query: {ws: string} ): Promise<GetNotificationsResponse> {
    return await this.fetch("/account/api/ws/notification/get", requestData, query, {});
  }
  
  async notification_toggle_unread(requestData: ToggleUnreadRequest, query: {ws: string} ): Promise<ToggleUnreadResponse> {
    return await this.fetch("/account/api/ws/notification/toggle_unread", requestData, query, {});
  }
  
  async notification_mark_all_as_read(requestData: MarkAllAsReadRequest, query: {ws: string} ): Promise<MarkAllAsReadResponse> {
    return await this.fetch("/account/api/ws/notification/mark_all_as_read", requestData, query, {});
  }
  
  async notification_has_unread(requestData: HasUnreadRequest, query: {ws: string} ): Promise<HasUnreadResponse> {
    return await this.fetch("/account/api/ws/notification/has_unread", requestData, query, {});
  }
  
  async notifSetting_get(requestData: GetNotifSettingRequest, query: {ws: string} ): Promise<NotifSettingValues> {
    return await this.fetch("/account/api/ws/notifSetting/get", requestData, query, {});
  }
  
  async notifSetting_upsert(requestData: UpsertNotifSettingRequest, query: {ws: string} ): Promise<NotifSettingValues> {
    return await this.fetch("/account/api/ws/notifSetting/upsert", requestData, query, {});
  }
  
  async group_findGroups(requestData: FindGroupsRequest, query: {ws: string} ): Promise<FindGroupsResponse> {
    return await this.fetch("/account/api/ws/group/findGroups", requestData, query, {});
  }
  
  async group_getGroupPermissionsForObj(requestData: GetGroupPermissionsForObjRequest, query: {ws: string} ): Promise<GetGroupPermissionsForObjResponse> {
    return await this.fetch("/account/api/ws/group/getGroupPermissionsForObj", requestData, query, {});
  }
  
  async group_createGroup(requestData: CreateGroupRequest, query: {ws: string} ): Promise<CreateGroupResponse> {
    return await this.fetch("/account/api/ws/group/createGroup", requestData, query, {});
  }
  
  async group_renameGroup(requestData: RenameGroupRequest, query: {ws: string} ): Promise<RenameGroupResponse> {
    return await this.fetch("/account/api/ws/group/renameGroup", requestData, query, {});
  }
  
  async group_updateGroupIcon(requestData: UpdateGroupIconRequest, query: {ws: string} ): Promise<UpdateGroupIconResponse> {
    return await this.fetch("/account/api/ws/group/updateGroupIcon", requestData, query, {});
  }
  
  async group_deleteGroupIcon(requestData: DeleteGroupIconRequest, query: {ws: string} ): Promise<DeleteGroupIconResponse> {
    return await this.fetch("/account/api/ws/group/deleteGroupIcon", requestData, query, {});
  }
  
  async group_deleteGroup(requestData: DeleteGroupRequest, query: {ws: string} ): Promise<DeleteGroupResponse> {
    return await this.fetch("/account/api/ws/group/deleteGroup", requestData, query, {});
  }
  
  async group_addGroupOwner(requestData: AddGroupOwnerRequest, query: {ws: string} ): Promise<AddGroupOwnerResponse> {
    return await this.fetch("/account/api/ws/group/addGroupOwner", requestData, query, {});
  }
  
  async group_removeGroupOwner(requestData: RemoveGroupOwnerRequest, query: {ws: string} ): Promise<RemoveGroupOwnerResponse> {
    return await this.fetch("/account/api/ws/group/removeGroupOwner", requestData, query, {});
  }
  
  async auditlog_searchAuditLogEntries(requestData: SearchAuditLogEntriesRequest, query: {ws: string} ): Promise<SearchAuditLogEntriesResponse> {
    return await this.fetch("/account/api/ws/auditlog/searchAuditLogEntries", requestData, query, {});
  }
  
  async apiKey_findApiKeys(requestData: FindApiKeysRequest, query: {ws: string} ): Promise<FindApiKeysResponse> {
    return await this.fetch("/account/api/ws/apiKey/findApiKeys", requestData, query, {});
  }
  
  async apiKey_createApiKey(requestData: CreateApiKeyRequest, query: {ws: string} ): Promise<CreateApiKeyResponse> {
    return await this.fetch("/account/api/ws/apiKey/createApiKey", requestData, query, {});
  }
  
  async apiKey_renameApiKey(requestData: RenameApiKeyRequest, query: {ws: string} ): Promise<RenameApiKeyResponse> {
    return await this.fetch("/account/api/ws/apiKey/renameApiKey", requestData, query, {});
  }
  
  async apiKey_deleteApiKey(requestData: DeleteApiKeyRequest, query: {ws: string} ): Promise<DeleteApiKeyResponse> {
    return await this.fetch("/account/api/ws/apiKey/deleteApiKey", requestData, query, {});
  }
  
  async apiKey_getPlainSecret(requestData: GetPlainSecretRequest, query: {ws: string} ): Promise<GetPlainSecretResponse> {
    return await this.fetch("/account/api/ws/apiKey/getPlainSecret", requestData, query, {});
  }
  
  async apiKey_appendNewSecret(requestData: AppendNewSecretRequest, query: {ws: string} ): Promise<AppendNewSecretResponse> {
    return await this.fetch("/account/api/ws/apiKey/appendNewSecret", requestData, query, {});
  }
  
  async apiKey_removeSecretVersion(requestData: RemoveSecretVersionRequest, query: {ws: string} ): Promise<RemoveSecretVersionResponse> {
    return await this.fetch("/account/api/ws/apiKey/removeSecretVersion", requestData, query, {});
  }
  
  async account_update(requestData: UpdateAccountRequest, query: {} = {} ): Promise<UpdateAccountResponse> {
    return await this.fetch("/account/api/account/update", requestData, query, {});
  }
  
  async account_updateProfileImage(requestData: UpdateAccountProfileImageRequest, query: {} = {} ): Promise<UpdateAccountProfileImageResponse> {
    return await this.fetch("/account/api/account/updateProfileImage", requestData, query, {});
  }
  
  async account_updateProfileImageFromGravatar(requestData: UpdateAccountProfileImageFromGravatarRequest, query: {} = {} ): Promise<UpdateAccountProfileImageFromGravatarResponse> {
    return await this.fetch("/account/api/account/updateProfileImageFromGravatar", requestData, query, {});
  }
  
  async account_deleteProfileImage(requestData: DeleteAccountProfileImageRequest, query: {} = {} ): Promise<DeleteAccountProfileImageResponse> {
    return await this.fetch("/account/api/account/deleteProfileImage", requestData, query, {});
  }
  
  async account_updatePreferences(requestData: UpdatePreferencesRequest, query: {} = {} ): Promise<UpdatePreferencesResponse> {
    return await this.fetch("/account/api/account/updatePreferences", requestData, query, {});
  }
  
  async account_updateEmail(requestData: UpdateAccountEmailRequest, query: {} = {} ): Promise<UpdateAccountEmailResponse> {
    return await this.fetch("/account/api/account/updateEmail", requestData, query, {});
  }
  
  async account_doneOnboarding(requestData: DoneOnboardingRequest, query: {} = {} ): Promise<DoneOnboardingResponse> {
    return await this.fetch("/account/api/account/doneOnboarding", requestData, query, {});
  }
  
  async account_getIpCountry(requestData: GetIpCountryRequest, query: {} = {} ): Promise<GetIpCountryResponse> {
    return await this.fetch("/account/api/account/getIpCountry", requestData, query, {});
  }
  
  async accountSetting_get(requestData: GetAccountSettingRequest, query: {} = {} ): Promise<GetAccountSettingResponse> {
    return await this.fetch("/account/api/accountSetting/get", requestData, query, {});
  }
  
  async accountSetting_updateKeyboardShortcuts(requestData: UpdateKeyboardShortcutsRequest, query: {} = {} ): Promise<UpdateKeyboardShortcutsResponse> {
    return await this.fetch("/account/api/accountSetting/updateKeyboardShortcuts", requestData, query, {});
  }
  
  async waitlist_checkValidCode(requestData: CheckValidCodeRequest, query: {} = {} ): Promise<CheckValidCodeResponse> {
    return await this.fetch("/account/api/waitlist/checkValidCode", requestData, query, {});
  }
  
  async waitlist_postConnectionRequestForm(requestData: PostConnectionRequestFormRequest, query: {} = {} ): Promise<PostConnectionRequestFormResponse> {
    return await this.fetch("/account/api/waitlist/postConnectionRequestForm", requestData, query, {});
  }
  
  async workspace_getAll(requestData: GetWorkspacesRequest, query: {} = {} ): Promise<GetWorkspacesResponse> {
    return await this.fetch("/account/api/workspace/getAll", requestData, query, {});
  }
  
  async workspace_uploadIcon(requestData: UploadWorkspaceIconRequest, query: {} = {} ): Promise<UploadWorkspaceIconResponse> {
    return await this.fetch("/account/api/workspace/uploadIcon", requestData, query, {});
  }
  
  async workspace_create(requestData: CreateWorkspaceRequest, query: {} = {} ): Promise<CreateWorkspaceResponse> {
    return await this.fetch("/account/api/workspace/create", requestData, query, {});
  }
  
  async workspace_getInvitedWorkspaces(requestData: GetInvitedWorkspacesRequest, query: {} = {} ): Promise<GetInvitedWorkspacesResponse> {
    return await this.fetch("/account/api/workspace/getInvitedWorkspaces", requestData, query, {});
  }
  
  async workspace_getJoinableWorkspaces(requestData: GetJoinableWorkspacesRequest, query: {} = {} ): Promise<GetJoinableWorkspacesResponse> {
    return await this.fetch("/account/api/workspace/getJoinableWorkspaces", requestData, query, {});
  }
  
  async workspace_joinWorkspace(requestData: JoinWorkspaceRequest, query: {} = {} ): Promise<JoinWorkspaceResponse> {
    return await this.fetch("/account/api/workspace/joinWorkspace", requestData, query, {});
  }
  
  async workspace_joinWorkspaceViaInviteLink(requestData: JoinWorkspaceViaInviteLinkRequest, query: {} = {} ): Promise<JoinWorkspaceViaInviteLinkResponse> {
    return await this.fetch("/account/api/workspace/joinWorkspaceViaInviteLink", requestData, query, {});
  }
  
  async wsinvitation_activateWorkspaceInvitation(requestData: ActivateWorkspaceInvitationRequest, query: {} = {} ): Promise<ActivateWorkspaceInvitationResponse> {
    return await this.fetch("/account/api/wsinvitation/activateWorkspaceInvitation", requestData, query, {});
  }
  
  async wsinvitation_declineWorkspaceInvitation(requestData: DeclineWorkspaceInvitationRequest, query: {} = {} ): Promise<DeclineWorkspaceInvitationResponse> {
    return await this.fetch("/account/api/wsinvitation/declineWorkspaceInvitation", requestData, query, {});
  }
  
  async notification_counts(requestData: GetNotificationCountsRequest, query: {} = {} ): Promise<GetNotificationCountsResponse> {
    return await this.fetch("/account/api/notification/counts", requestData, query, {});
  }
  
  async workspace_createForAdmin(requestData: CreateWorkspaceForAdminRequest, query: {} = {} ): Promise<CreateWorkspaceForAdminResponse> {
    return await this.fetch("/account/api/workspace/createForAdmin", requestData, query, {});
  }
  
  async workspace_updateForAdmin(requestData: UpdateWorkspaceForAdminRequest, query: {} = {} ): Promise<UpdateWorkspaceForAdminResponse> {
    return await this.fetch("/account/api/workspace/updateForAdmin", requestData, query, {});
  }
  
  async workspace_deleteForAdmin(requestData: DeleteWorkspaceForAdminRequest, query: {} = {} ): Promise<DeleteWorkspaceForAdminResponse> {
    return await this.fetch("/account/api/workspace/deleteForAdmin", requestData, query, {});
  }
  
  async workspace_getFeaturesAndLimitsForAdmin(requestData: GetFeaturesAndLimitsForAdminRequest, query: {} = {} ): Promise<GetFeaturesAndLimitsForAdminResponse> {
    return await this.fetch("/account/api/workspace/getFeaturesAndLimitsForAdmin", requestData, query, {});
  }
  
  async workspace_findAllWorkspacesForAdmin(requestData: FindAllWorkspacesForAdminRequest, query: {} = {} ): Promise<FindAllWorkspacesForAdminResponse> {
    return await this.fetch("/account/api/workspace/findAllWorkspacesForAdmin", requestData, query, {});
  }
  
  async workspace_getWorkspaceDetailForAdmin(requestData: GetWorkspaceDetailForAdminRequest, query: {} = {} ): Promise<GetWorkspaceDetailForAdminResponse> {
    return await this.fetch("/account/api/workspace/getWorkspaceDetailForAdmin", requestData, query, {});
  }
  
  async account_findAllAccountsByIdsForAdmin(requestData: FindAllAccountsByIdsForAdminRequest, query: {} = {} ): Promise<FindAllAccountsByIdsForAdminResponse> {
    return await this.fetch("/account/api/account/findAllAccountsByIdsForAdmin", requestData, query, {});
  }
  
  async debugging_simulateError(requestData: SimulateErrorRequest, query: {ws: string} ): Promise<SimulateErrorResponse> {
    return await this.fetch("/account/api/debugging/simulateError", requestData, query, {});
  }
  
  async debugging_invokeInternalEvent(requestData: InvokeInternalEventRequest, query: {} = {} ): Promise<InvokeInternalEventResponse> {
    return await this.fetch("/account/api/debugging/invokeInternalEvent", requestData, query, {});
  }
  
}
