import type { App } from 'vue';

type IdentifyPropsBase = {
  id: string;
  name: string;
  email: string;
};

export type IdentifyProps = IdentifyPropsBase & {
  intercomHash?: string;
};

export type IdentifyPropsWithCompany = IdentifyPropsBase & {
  company: { id: string; name: string; iconUrl: string | null };
};

export const TRACKER_MODULE_TYPES = {
  CANNY: 'CANNY',
  SEGMENT: 'SEGMENT',
  GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
  INTERCOM: 'INTERCOM',
  KARTE: 'KARTE',
} as const;

type TrackerModuleType = typeof TRACKER_MODULE_TYPES[keyof typeof TRACKER_MODULE_TYPES];

export interface TrackerModule {
  moduleType: TrackerModuleType;
  init(app: App): void;
  identify(properties: IdentifyProps): void;
  identifyWithCompany(properties: IdentifyPropsWithCompany): void;
  view(): void;
  track(event: string, properties?: any): void;
  openChat(): void;
}
