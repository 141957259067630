import { reactive } from 'vue';
import type { Account, Workspace } from '@cdm/@generated-account-client/client';
import { cloneDeep } from 'lodash-es';
import type { Preferences } from '@cdm/@generated-account-client/shared-types';
import { SettingStore } from '../../stores/SettingStore';
import { PermissionStore } from './PermissionStore';
import { getToken, decodeJwt, getIdToken } from '@cdm/libs/auth';
import { accountClient } from '@cdm/clients/AccountClient';
import { getAccountDisplayName } from '@cdm/domains/workspace/shared/hooks/useWorkspaceAccounts';
import { accountAuthClient } from '@cdm/clients/AccountAuthClient';

const DEFAULT_PREFERENCES: Preferences = {
  sql: {
    enableAutoComplete: true,
    enableCodeSnippetAutocomplete: true,
    enableFunctionAutocomplete: true,
    enableFunctionTooltip: true,
    enableRefItemFieldAutocomplete: true,
    enableReservedWordAutocomplete: true,
    enableTabIndent: false,
    indentSize: 2,
    preferLowerKeyword: false,
    addAliasByDefault: false,
    disableAiCodeSuggestion: false,
  },
};

class AccountStore {
  public permission: PermissionStore;
  public setting: SettingStore;

  private state: {
    account: Account | null;
    accountEmail: string;
    workspace: Workspace | null;
    nextWorkspaceId: string | null;
    isAdmin: boolean;
  };

  constructor() {
    this.permission = new PermissionStore();
    this.setting = new SettingStore();
    this.state = reactive({
      account: null,
      accountEmail: '',
      workspace: null,
      nextWorkspaceId: null,
      isAdmin: false,
    });
  }

  public isInitialized() {
    return this.state.account !== null;
  }

  public async fetchAccount() {
    if (this.state.account) return;

    const idToken = await getIdToken();
    const resp = await accountAuthClient.account_get({ idToken });
    const token = await getToken();
    const claims = decodeJwt(token);
    this.state.account = resp.account;
    this.state.accountEmail = claims.email;
    this.state.isAdmin = claims.isAdmin;
  }

  public async refreshAccount() {
    const idToken = await getIdToken();
    const resp = await accountAuthClient.account_get({ idToken });
    this.state.account = resp.account;
  }

  private get account(): Account {
    // todo: テストの場合は外側から差し込めるようにした方が良さそう？
    if (import.meta.env.VITE_IS_CYPRESS === 'true') {
      return {
        full_name: '',
        id: '',
        profile_image_url: '',
        view_name: '',
        intercom_hash: '',
        preferences: cloneDeep(DEFAULT_PREFERENCES),
        onboarding: false,
      };
    }

    return this.state.account as Account;
  }

  public getAccount(): Account | null {
    return this.account;
  }

  public getAccountId(): string {
    return this.account.id;
  }

  public getAccountName(): string {
    return getAccountDisplayName(this.account);
  }

  public getAccountViewName(): string {
    return this.account.view_name;
  }

  public getAccountFullName(): string {
    return this.account.full_name;
  }

  public getAccountProfileImageUrl(): string {
    return this.account.profile_image_url;
  }

  public getAccountEmail(): string {
    return this.state.accountEmail;
  }

  public getAccountIntercomHash(): string {
    return this.account.intercom_hash;
  }

  public getPreference<C extends keyof Preferences>(context: C): Preferences[C] {
    const current = this.account?.preferences?.[context];
    return (current as Preferences[C]) || DEFAULT_PREFERENCES[context];
  }

  public getDefaultPreference<C extends keyof Preferences>(context: C): Preferences[C] {
    return DEFAULT_PREFERENCES[context];
  }

  public getAccountIsAdmin(): boolean {
    return !!this.state.isAdmin;
  }

  public getAccountIsOnboarding(): boolean {
    return this.account.onboarding;
  }

  public clearWorkspace() {
    this.state.nextWorkspaceId = null;
    this.state.workspace = null;
  }

  public async checkAndSetWorkspace(wsId: string): Promise<void> {
    if (this.state.workspace?.id === wsId) {
      return;
    }

    try {
      this.state.nextWorkspaceId = wsId;
      await this.fetchWorkspace(wsId);
      await this.permission.fetchPermissions(wsId);
      await this.setting.fetchSetting();
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      this.state.nextWorkspaceId = null;
    }
  }

  public async refreshWorkspace() {
    const workspaceId = this.state.workspace?.id;
    if (!workspaceId) return;

    await this.fetchWorkspace(workspaceId);
    return;
  }

  private async fetchWorkspace(workspaceId: string) {
    const resp = await accountClient.workspace_get({ data: {} }, { ws: workspaceId });
    if (resp.workspace.id === workspaceId) {
      this.state.workspace = resp.workspace;
    }
  }

  public getWorkspaceName(): string {
    return this.state.workspace?.name ?? '';
  }

  public getWorkspaceIcon(): string | null {
    return this.state.workspace?.icon_url ?? null;
  }

  public getWorkspace(): Workspace | null {
    return this.state.workspace;
  }

  public getWorkspaceId(): string | null {
    return this.state.nextWorkspaceId ?? this.state.workspace?.id ?? null;
  }

  public getApiContext(): { wsId: string } {
    if (!this.state.workspace) {
      throw new Error('workspace not found');
    }
    return { wsId: this.state.workspace.id };
  }
}

// ユーザの状態はグローバルで唯一なので singleton
export default new AccountStore();
